<template>
  <div class="authentication-detail">
    <app-header :title="title" :isShowBack="true"></app-header>
    <div>
      <app-detail :opt="opt"></app-detail>
      <!-- <div class="li_title">
        <app-detail :opt="opt2"></app-detail>
        <span class="lis_span">如有退房有缴费则显示如下内容</span>
      </div>-->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
      DataList: {},
      id: "",
      title: "退房详情",
      opt: {
        form: [
          {
            label: "房客信息",
            type: "TitleName"
          },
          {
            label: "房源信息",
            key: "address",
            isCol50: true
          },
          {
            label: "租赁模式",
            key: "rentalStyleStr",
            isCol50: true
          },
          {
            label: "房客姓名",
            key: "tenantRealname",
            isCol50: true
          },
          {
            label: "起始日期",
            key: "rentStartDate",
            filters: {
              key: "isShow",
              value: "1"
            },
            isCol50: true
          },
          {
            label: "入住开始时间",
            key: "rentStartDate",
            filters: {
              key: "rentalStyle",
              value: "3"
            },
            isCol50: true
          },
          {
            label: "房客手机号码",
            key: "tenantPhone",
            isCol50: true
          },
          {
            label: "截止日期",
            key: "rentEndDate",
            filters: {
              key: "isShow",
              value: "1"
            },
            isCol50: true
          },
          {
            label: "入住截止时间",
            key: "rentEndDate",
            filters: {
              key: "rentalStyle",
              value: "3"
            },
            isCol50: true
          },
          {
            label: "退房日期",
            key: "quitDate",
            filters: {
              key: "isShow",
              value: "1"
            },
            isCol50: true
          },
          {
            label: "退房时间",
            key: "quitDate",
            filters: {
              key: "rentalStyle",
              value: "3"
            },
            isCol50: true
          },
          {
            label: "入住时长",
            key: "hours",
            filters: {
              key: "rentalStyle",
              value: "3"
            },
            isCol50: true
          },
          {
            label: "提交申请退房时间",
            key: "quitCreateDate",
            isCol50: true
          },
          {
            label: "退房信息",
            type: "TitleName"
          },
          {
            label: "退款单号",
            key: "refundNo",
            isCol50: true
          },
          {
            label: "退款状态",
            key: "stateStr",
            isCol50: true
          },
          {
            label: "退款总金额（元）",
            key: "checkoutSum",
            isCol50: true
          },
          {
            label: "物业费应退（元）",
            key: "checkoutPropertyFee",
            filters: {
              key: "rentalStyle",
              value: "0"
            },
            isCol50: true
          },
          {
            label: "租金应退（元）",
            key: "checkoutRentalFee",
            isCol50: true
          },
          {
            label: "押金应退（元）",
            key: "checkoutDepositFee",
            isCol50: true
          },
          {
            label: "扣款金额（元）",
            key: "deductionFee",
            isCol50: true
          },
          {
            label: "退款到账时间",
            key: "successTime",
            isCol50: true
          },
          // {
          //   label: "平台批准退款时间",
          //   key: "approveTime",
          //   isCol50: true
          // },
          {
            label: "退款失败原因",
            key: "failureReason",
            isCol50: true,
            filters: [{
               key: "state",
               value: "6"
            }]
          }
        ],
        get(opt) {
          let dto = {
            quitOrderId: _this.$route.params.id
          };
          // for (let index in _this.opt.form) {
          //   let item = _this.opt.form;
          //   if (_this.$route.query.rentalStyle == 1 || _this.$route.query.rentalStyle == 3) {
          //     if (item[index]["key"] == "checkoutPropertyFee") {
          //       item.splice(13, 1);
          //     }
          //   } else if  ( _this.$route.query.rentalStyle == 0) {
              // _this.opt.form.splice(7, 1, {
              //   label: "退房日期",
              //   key: "quitDate",
              //   isCol50: true
              // });
          //     _this.opt.form.splice(13, 1, {
          //       label: "物业费应退（元）",
          //       key: "checkoutPropertyFee",
          //       isCol50: true
          //     });
          //   }
          // }
          let url =
            _this.$route.query.rentalStyle == 1
              ? "landlord-service/shortRentOrder/quitDetail"
              : "tenant-service/leaseroomdetail/quitDetail";
              // ? "tenant-service/leaseroomdetail/quitDetail"
              // : "landlord-service/shortRentOrder/quitDetail";
          _this.post(url, dto).then(data => {
            // isSchoolType该值需要在接口获取到后赋值
            if(_this.isSchoolType) {
              _this.opt.form.forEach((item,index) => {
                if(item.label == "房客信息") {
                  item.label = "  "
                }
                if(item.label == "退房信息") {
                  _this.opt.form.splice(index,9)
                }
              })
            }
            if (data.state == "-1") {
              data.stateStr = "--";
            } else {
              data.stateStr = [
                "退款中",
                "退款中",
                "退款成功",
                "退款异常",
                "退款取消",
                "退款失败",
                "退款失败"
              ][data.state];
              data.failureReason = "退款订单支付时间超过一年，不支持退款";
            }
            data.tenantPhone = data.tenantPhone
              ? data.tenantPhone.substring(0, 3) +
                "****" +
                data.tenantPhone.substring(7, data.tenantPhone.length)
              : "";
            data.isShow = data.rentalStyle == 3 ? 0 : 1;
            data.rentalStyleStr = ["长租", "短租", "长短租","钟点房"][data.rentalStyle];
            data.refundFee = data.refundFee == "0" ? "0" : data.refundFee / 100;
            data.deductionFee =
              data.deductionFee == null ? "0" : data.deductionFee;
            
            //计算时间差
            var t1 = new Date(data.rentStartDate);
            var t2 = new Date(data.rentEndDate);
            var hours = t2.getTime() - t1.getTime();
            var leave = hours%(24*3600*1000);
            var h = parseInt(leave/(3600*1000));
            var h_leave = leave%(3600*1000);
            var min = parseInt(h_leave/(60*1000));
            var min_leave = h_leave%(60*1000);
            var sec = parseInt(min_leave/1000);
              // item.hours = t1
              if(h==0 && min==0 && sec==0){
                data.hours = '--'
              }else{
                data.hours = h + '小时' + min + '分' +  sec + '秒'
              }      
            opt.cb(data);
          });
        }
      },
      isSchoolType: false,//是否是学校类型的房源
    };
  }
  //   activated: function() {
  //     this.id = this.$route.params.id || "";
  //     console.log("sale-detail created!!");
  //   }
};
</script>
<style lang="scss">
.authentication-detail {
  .li_title {
    // width: 100%;
    padding: 20px 30px 0 30px;
    border: 1px dashed #333333;
    position: relative;
    top: 0;
    left: 0;
    .lis_span {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      position: absolute;
      top: -12px;
      left: 20px;
      padding: 0 10px;
      background-color: #fff;
    }
  }
}
</style>

